import React from "react"
import AirbnbPreview from "./airbnbPreview"

const Apartments = () => {
  return (
    <>
      <section className="container">
        <h1>Nuestros departamentos a través de Airbnb</h1>
        <div className="apartments">
          <AirbnbPreview
            dataId={43110333}
            dataUrl={"https://www.airbnb.com/rooms/43110333"}
            dataDescription={"Lidxi Rosae Apt 1 Zicatela"}
          />
          <AirbnbPreview
            dataId={43469740}
            dataUrl={"https://www.airbnb.com/rooms/43469740"}
            dataDescription={"Lidxi Rosae Apt 2 Zicatela"}
          />
          <AirbnbPreview
            dataId={43542022}
            dataUrl={"https://www.airbnb.com/rooms/43542022"}
            dataDescription={"Lidxi Rosae Apt 3 Zicatela"}
          />
          <AirbnbPreview
            dataId={43542453}
            dataUrl={"https://www.airbnb.com/rooms/43542453"}
            dataDescription={"Lidxi Rosae Apt 4 Zicatela"}
          />
          <AirbnbPreview
            dataId={45336103}
            dataUrl={"https://www.airbnb.com/rooms/45336103"}
            dataDescription={"Lidxi Rosae Apt 5 Zicatela"}
          />
          <AirbnbPreview
            dataId={45336446}
            dataUrl={"https://www.airbnb.com/rooms/45336446"}
            dataDescription={"Lidxi Rosae Apt 6 Zicatela"}
          />
          <AirbnbPreview
            dataId={44212579}
            dataUrl={"https://www.airbnb.com/rooms/44212579"}
            dataDescription={"Lidxi Rosae Apt 7 Zicatela"}
          />
        </div>
      </section>
    </>
  )
}

export default Apartments
