import React from 'react'
// import { GrFacebook, GrInstagram } from 'react-icons/fa';
import { FaInstagram, FaFacebook } from 'react-icons/fa';

const Footer = () => {

  return (
    <footer>
      <section>
        <div className="address">
          <h3>¿DONDE NOS ENCONTRAMOS?</h3>
          <span>Las Brisas (casi esquina de carretera costera)</span>
          <span>70934 Zicatela, Puerto Escondido, Oaxaca, México</span>
        </div>
        <div className="hours">
          <h3>HORARIOS</h3>
          <span>En este momento solo hacemos reservaciones en línea por Airbnb</span>
        </div>
        <div className="contact">
          <h3>CONTACTO</h3>
          <span><a href="https://www.instagram.com/lidxirosae/" target="_blank" rel="noopener noreferrer"><FaInstagram size="30px"/> </a></span>
          <span><a href="https://www.facebook.com/Lidxi-Rosae-102197374917302" target="_blank" rel="noopener noreferrer"><FaFacebook size="30px"/> </a></span>
        </div>
      </section>
    </footer>
  )
}

export default Footer;
