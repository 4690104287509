import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Apartments from '../components/apartments'
import LeafletMap from '../components/leafletMap'
import Footer from '../components/footer'

const IndexPage = ({data}) => {

return (
  <Layout>
    <SEO title="Home" />
    <section className="hero">
      <Img fluid={data.lidxiHero.childImageSharp.fluid} className="hero-image"/>
    </section>
    <section className="container">
      <span className="description">
        Somos una nueva opción de confort en Zicatela para tus próximas vacaciones
      </span>
      <div className="gallery-grid">
        <Img fluid={data.lidxiRoom.childImageSharp.fluid} className="gallery-img1"/>
        <Img fluid={data.lidxiPool.childImageSharp.fluid} className="gallery-img2"/>
        <Img fluid={data.lidxiPortrait.childImageSharp.fluid} className="gallery-img3"/>
      </div>
      <div className="about-grid">
        <h2>Departamentos equipados</h2>
        <p>Nuestros departamentos cuentan con cocineta, frigobar, sala, TV, ventilador y aire acondicionado, baño privado. A tan solo unos metros de playa Zicatela</p>
      </div>
    </section>
    <div className="parallax"></div>
    <section className="container">
      <Apartments />
    </section>
    {typeof window !== 'undefined' &&
      <LeafletMap
        position={[15.85185, -97.05254]} // Your Coordinates
        zoom={18} // Zoom Level
        markerText={"Lidxi Rosae, Las Brisas"} // Icon text
      />
    }
    <Footer />
  </Layout>
  )
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    lidxiHero: file(relativePath: { eq: "lidxi-hero.jpg" }) {
      ...fluidImage
    }
    lidxiPortrait: file(relativePath: { eq: "lidxi-kitchen.jpeg" }) {
      ...fluidImage
    }
    lidxiRoom: file(relativePath: { eq: "lidxi-room.jpeg" }) {
      ...fluidImage
    }
    lidxiPool: file(relativePath: { eq: "lidxi-pool.jpeg" }) {
      ...fluidImage
    }
  }
`;

export default IndexPage
