import React from "react"
import scriptLoader from "react-async-script-loader"

const airbnbPreview = ({
  isScriptLoaded,
  isScriptLoadSucceed,
  dataId,
  dataUrl,
  dataDescription,
}) => {
  if (isScriptLoaded && isScriptLoadSucceed) {
    window.AirbnbAPI.bootstrap()
  }

  return (
    <div className="apartment-column">
      <div
        className="airbnb-embed-frame"
        data-id={dataId}
        data-view="home"
        data-hide-price="true"
        style={{ width: 450, height: 300, margin: "auto" }}
      >
        <a href={dataUrl}>
          <span>Ver en Airbnb</span>
        </a>
        <a href={dataUrl} rel="nofollow">
          {dataDescription}
        </a>
      </div>
    </div>
  )
}

export default scriptLoader(["https://www.airbnb.com/embeddable/airbnb_jssdk"])(
  airbnbPreview
)
